import React from 'react'

const FeedBack = () => {
  return (
    <div className='container'>
      <div className='content_wrapper text_size'>
        <h1 className='mr_head'>Feedback</h1>
        <p className='mr_content'>
          Customer satisfaction is very important to us, however it has to be achieved within the
          parameters of our service level agreements. We always aim to provide a high quality
          service whilst adhering to our framework parameters.
        </p>
        <p className='mr_content'>
          We see our DSA customers as students in receipt of the DSA but also other professionals
          working in this industry such as Needs Assessors and Disability Advisors. We are always
          keen to hear back from people we assist in the workplace and often maintain long term
          relationships with our clients.
        </p>
        <p className='mr_content'>
          Students - please send your feedback to
          <a className='link__order_form' href='mailto: studentfeedback@as-dsa.com'>
            studentfeedback@as-dsa.com
          </a>
        </p>
        <p className='mr_content'>
          Industry professionals - please send your feedback to
          <a className='link__order_form' href='mailto:  feedback@as-dsa.com'>
            feedback@as-dsa.com
          </a>
        </p>
        <iframe
          src='https://docs.google.com/forms/d/e/1FAIpQLSdO9oeTEUJTf9mnAUg9oR0bSYhTQOILhdkr3xaWcEvLdSvVwQ/viewform?embedded=true'
          width='100%'
          height='1000'
          frameBorder='0'>
          Loading…
        </iframe>
      </div>
    </div>
  )
}

export default FeedBack
