import {
  circle_of_control,
  asperger,
  bsl_tutors,
  essay,
  dwarf,
  ed,
  dsa,
  dsa2,
  rsa,
  turning,
  turning2,
  turning3,
  turning4,
  turning5,
  turning6,
  empl,
  empl1,
  empl2,
  pic_1,
  pic_2,
  pic_3,
  pic_4,
  pic_5,
  pic_6,
  pic_7,
  pic_8,
  pic_9,
  pic_10,
  pic_11,
  pic_12,
  pic_13,
  pic_14,
  pic_15,
  pic_16,
  pic_17,
  pic_18,
  pic_19,
  pic_20,
  pic_21,
  pic_22,
  pic_23,
  pic_24,
  pic_25,
  pic_26,
  pic_27,
  pic_28,
  pic_29,
  pic_30,
  pic_31,
  pic_32,
  pic_33,
  pic_34,
  pic_35,
  pic_36,
  pic_37,
  pic_38,
  pic_39,
  pic_40
} from './img'

export const MENTAL_HEALTH = 'MENTAL_HEALTH'
export const AUTISM = 'AUTISM'
export const DEAF_AWARE = 'DEAF_AWARE'
export const SPLD_DYSLEXIA = 'SPLD_DYSLEXIA'
export const EDUCATION = 'EDUCATION'
export const DSA = 'DSA'
export const EMPLOYMENT = 'EMPLOYMENT'
export const ALL = 'ALL'
export const PROCRASTINATION = 'PROCRASTINATION'
export const EQUALITY_ACT = 'EQUALITY_ACT'
export const LEARNING_RESOURCES = 'LEARNING_RESOURCES'
export const ASCS = 'ASCS'
export const RECYCLING = 'RECYCLING'
export const FREE_SOFTWARE = 'FREE_SOFTWARE'
export const AT_TRAINING = 'AT_TRAINING'

export const studentsResources = {
  ALL: [
    {
      title: 'Circle of Control',
      img: circle_of_control,
      tags: [{ title: 'Mental Health', value: MENTAL_HEALTH }],
      link: '/circle-of-control'
    },
    {
      title: `Asperger's Confounds Colleges`,
      img: asperger,
      tags: [{ title: 'AUTISM', value: AUTISM }],
      link: '/aspergers-confounds-colleges'
    },
    {
      title: `Assistive Solutions BSL Tutors`,
      img: bsl_tutors,
      tags: [{ title: 'Deaf Aware', value: DEAF_AWARE }],
      link: '/assistive-solutions-bsl-tutors'
    },
    {
      title: `Essay Writing`,
      img: essay,
      tags: [{ title: 'SPLD / Dyslexia', value: SPLD_DYSLEXIA }],
      link: '/essay-writing'
    },
    {
      title: 'The Mental Elf: More mental health support needed for students in higher education',
      img: dwarf,
      tags: [{ title: 'Mental Health', value: MENTAL_HEALTH }],
      link: '/mental-elf'
    },
    {
      title: `Mental health and higher education: 'I won't let depression hold back my academic career'`,
      img: ed,
      tags: [
        { title: 'Mental Health / ', value: MENTAL_HEALTH },
        { title: 'Education', value: EDUCATION }
      ],
      link: '/mental-health-and-higher-education'
    },
    {
      title: `Studying with Social Distancing.`,
      img: pic_40,
      tags: [{ title: 'Mental Health', value: MENTAL_HEALTH }],
      link: '/studying-with-social-distancing'
    },
    {
      title: `DSA. What, when and how to apply`,
      img: dsa,
      tags: [{ title: 'DSA', value: DSA }],
      link: 'coming-soon'
    },
    {
      title: `Disabled Students’ Allowances (DSAs) in higher education 2013/14`,
      img: dsa2,
      tags: [{ title: 'DSA', value: DSA }],
      link: '/disabled-students-allowances'
    },
    {
      title: `RSA Animate: Changing Education Paradigms`,
      img: rsa,
      tags: [{ title: 'Education', value: EDUCATION }],
      link: '/rsa-animate-changing-education-paradigms'
    },
    {
      title: `Turning Learning Right Side Up Book Talk`,
      img: turning,
      tags: [{ title: 'Education', value: EDUCATION }],
      link: '/turning-learning-right-side-up-book-talk'
    },
    {
      title: `Salman Khan talk at TED 2011`,
      img: turning2,
      tags: [{ title: 'Education', value: EDUCATION }],
      link: '/salman-khan-talk-at-ted-2011'
    },
    {
      title: `Disabled students ‘still need more help’, says report`,
      img: turning3,
      tags: [{ title: 'Education', value: EDUCATION }],
      link: '/disabled-students-still-need-more-help'
    },
    {
      title: `Five secrets to revising that can boost your grades`,
      img: turning4,
      tags: [{ title: 'Education', value: EDUCATION }],
      link: '/five-secrets-to-revising-that-can-boost-your-grades'
    },
    {
      title: `My Life as a Disabled Student`,
      img: turning5,
      tags: [{ title: 'Education', value: EDUCATION }],
      link: '/my-life-as-a-disabled-student'
    },
    {
      title: `Students: 10 ways to beat stress`,
      img: turning6,
      tags: [{ title: 'Education', value: EDUCATION }],
      link: '/students-10-ways-to-beat-stress'
    },
    {
      title: `Students: how to use LinkedIn at uni to help you get a job when you graduate`,
      img: empl,
      tags: [{ title: 'Employment', value: EMPLOYMENT }],
      link: '/students-how-to-use-linkedin-at-uni-to-help-you-get-a-job-when-you-graduate'
    },
    {
      title: `The ten skills students really need when they graduate`,
      img: empl1,
      tags: [{ title: 'Employment', value: EMPLOYMENT }],
      link: '/the-ten-skills-students-really-need-when-they-graduate'
    },
    {
      title: `Will my disability affect my graduate job application?`,
      img: empl2,
      tags: [{ title: 'Employment', value: EMPLOYMENT }],
      link: '/will-my-disability-affect-my-graduate-job-application'
    },
    {
      title: `How to escape education's death valley | Sir Ken Robinson`,
      img: pic_1,
      tags: [
        { title: 'All Content/', value: ALL },
        { title: 'AT Training/', value: AT_TRAINING },
        { title: 'Education', value: EDUCATION }
      ],
      link: 'coming-soon'
    },
    {
      title: `When will we understand Autism Spectrum Disorders?`,
      img: pic_2,
      tags: [{ title: 'AUTISM', value: AUTISM }],
      link: '/when-will-we-understand-autism-spectrum-disorders'
    },
    {
      title: `Autism: how computers can help`,
      img: pic_3,
      tags: [{ title: 'AUTISM', value: AUTISM }],
      link: '/autism-how-computer-can-help'
    },
    {
      title: `Asperger's: 'This is our normal'`,
      img: pic_4,
      tags: [{ title: 'AUTISM', value: AUTISM }],
      link: '/asperger-is-this-is-our-normal'
    },
    {
      title: `Snowdon Award Scheme`,
      img: pic_5,
      tags: [{ title: 'Deaf Aware', value: DEAF_AWARE }],
      link: '/snowdon-award-scheme'
    },
    {
      title: `Action on Hearing Loss`,
      img: pic_6,
      tags: [{ title: 'Deaf Aware', value: DEAF_AWARE }],
      link: 'coming-soon'
    },
    {
      title: `Why the Deaf Have Enhanced Vision`,
      img: pic_7,
      tags: [{ title: 'Deaf Aware', value: DEAF_AWARE }],
      link: '/why-the-deaf-have-enhanced-vision'
    },
    {
      title: `Joshua Foer: Feats of memory anyone can do`,
      img: pic_8,
      tags: [{ title: 'SPLD / Dyslexia', value: SPLD_DYSLEXIA }],
      link: '/joshua-foer-feats-of-memory-anyone-can-do'
    },
    {
      title: `In praise of misfits`,
      img: pic_9,
      tags: [{ title: 'SPLD / Dyslexia', value: SPLD_DYSLEXIA }],
      link: '/in-praise-of-misfits'
    },
    {
      title: `Diagnosing Dyslexia. A Guide to the Assessment of Adults with Specific Learning Difficulties`,
      img: pic_10,
      tags: [{ title: 'SPLD / Dyslexia', value: SPLD_DYSLEXIA }],
      link: '/diagnosing-dyslexia-guide-to-the-assessment-of-adults-with-specific-learning-difficulties'
    },
    {
      title: `Memrise`,
      img: pic_11,
      tags: [{ title: 'SPLD / Dyslexia', value: SPLD_DYSLEXIA }],
      link: '/memrise'
    },
    {
      title: `Secret of the super successful ...they’re dyslexic`,
      img: pic_12,
      tags: [{ title: 'SPLD / Dyslexia', value: SPLD_DYSLEXIA }],
      link: '/secret-of-the-super-successful-they-are-dyslexic'
    },
    {
      title: `Eleanor Longden: The voices in my head`,
      img: pic_13,
      tags: [{ title: 'Mental Health', value: MENTAL_HEALTH }],
      link: '/eleanor-longden-the-voices-in-my-head'
    },
    {
      title: `The Norah Fry Research Centre`,
      img: pic_14,
      tags: [{ title: 'Mental Health', value: MENTAL_HEALTH }],
      link: '/the-norah-fry-research-centre'
    },
    {
      title: `Mind, the mental health charity.`,
      img: pic_15,
      tags: [{ title: 'Mental Health', value: MENTAL_HEALTH }],
      href: 'https://www.mind.org.uk/'
    },
    {
      title: `SANE Charity`,
      img: pic_16,
      tags: [{ title: 'Mental Health', value: MENTAL_HEALTH }],
      href: 'https://www.sane.org.uk/'
    },
    {
      title: `Don't worry, be happy: overcoming worry may be key to mental health`,
      img: pic_17,
      tags: [{ title: 'Mental Health', value: MENTAL_HEALTH }],
      link: '/dont-worry-be-happy'
    },
    {
      title: `Why Procrastinators Procrastinate`,
      img: pic_18,
      tags: [{ title: 'Procrastination', value: PROCRASTINATION }],
      link: '/why-procrastinators-procrastinate'
    },
    {
      title: `How to Beat Procrastination`,
      img: pic_19,
      tags: [{ title: 'Procrastination', value: PROCRASTINATION }],
      link: '/how-to-beat-procrastination'
    },
    {
      title: `24 Invaluable Skills To Learn For Free Online This Year`,
      img: pic_39,
      tags: [{ title: 'Procrastination', value: PROCRASTINATION }],
      link: '/invaluable-skills-to-learn-for-free-online-this-year'
    },
    {
      title: `How To Get A Whole Lot Of Sh*t Done In A Day`,
      img: pic_20,
      tags: [{ title: 'Procrastination', value: PROCRASTINATION }],
      link: '/how-to-get-a-whole-lot-of-sh*t-done-in-a-day'
    },
    {
      title: `Meditation For People Who Don’t Meditate`,
      img: pic_21,
      tags: [{ title: 'Procrastination', value: PROCRASTINATION }],
      link: '/meditation-for-people-who-dont-meditate'
    },
    {
      title: `Equality Act 2010`,
      img: pic_22,
      tags: [{ title: 'Equality Act', value: EQUALITY_ACT }],
      link: '/equality-act'
    },
    {
      title: `Martyn Sibley Blog`,
      img: pic_23,
      tags: [{ title: 'Equality Act', value: EQUALITY_ACT }],
      link: '/martyn-sibley-blog'
    },
    {
      title: `Employability`,
      img: pic_24,
      tags: [{ title: 'Employment', value: EMPLOYMENT }],
      link: '/employability'
    },
    {
      title: `AchieveAbility`,
      img: pic_25,
      tags: [{ title: 'Employment', value: EMPLOYMENT }],
      href: 'https://www.achieveability.org.uk/'
    },
    {
      title: `Dropbox`,
      img: pic_26,
      tags: [{ title: 'Free software', value: FREE_SOFTWARE }],
      href: 'https://www.dropbox.com/'
    },
    {
      title: `Zotero`,
      img: pic_27,
      tags: [{ title: 'Free software', value: FREE_SOFTWARE }],
      href: 'https://www.zotero.org/'
    },
    {
      title: `World Web Dictionary`,
      img: pic_28,
      tags: [{ title: 'Free software', value: FREE_SOFTWARE }],
      href: 'http://www.wordweb.info/'
    },
    {
      title: `Evernote`,
      img: pic_29,
      tags: [{ title: 'Free software', value: FREE_SOFTWARE }],
      href: 'https://evernote.com/intl/ru'
    },
    {
      title: `XMind`,
      img: pic_30,
      tags: [{ title: 'Free software', value: FREE_SOFTWARE }],
      href: 'https://xmind.app/'
    },
    {
      title: `F.lux`,
      img: pic_31,
      tags: [{ title: 'Free software', value: FREE_SOFTWARE }],
      href: 'http://justgetflux.com/'
    },
    {
      title: `Nocturne`,
      img: pic_32,
      tags: [{ title: 'Free software', value: FREE_SOFTWARE }],
      href: 'https://www.macupdate.com/discontinued-apps'
    },
    {
      title: `Virtual Magnifying Glass`,
      img: pic_33,
      tags: [{ title: 'Free software', value: FREE_SOFTWARE }],
      href: 'https://www.macupdate.com/app/mac/41079/virtual-magnifying-glass'
    },
    {
      title: `Self Control`,
      img: pic_34,
      tags: [{ title: 'Free software', value: FREE_SOFTWARE }],
      href: 'http://selfcontrolapp.com/'
    },
    {
      title: `Q10`,
      img: pic_35,
      tags: [{ title: 'Free software', value: FREE_SOFTWARE }],
      href: 'http://www.baara.com/q10/'
    },
    {
      title: `Focus Writer`,
      img: pic_36,
      tags: [{ title: 'Free software', value: FREE_SOFTWARE }],
      href: 'https://gottcode.org/focuswriter/'
    },
    {
      title: `Firefox Reader`,
      img: pic_37,
      tags: [{ title: 'Free software', value: FREE_SOFTWARE }],
      href: 'https://addons.mozilla.org/en-US/firefox/addon/reader/'
    },
    {
      title: `Scrible`,
      img: pic_38,
      tags: [{ title: 'Free software', value: FREE_SOFTWARE }],
      href: 'http://www.scrible.com/'
    }
  ],
  MENTAL_HEALTH: [
    {
      title: 'Circle of Control',
      img: circle_of_control,
      tags: [{ title: 'Mental Health', value: MENTAL_HEALTH }],
      link: '/circle-of-control'
    },
    {
      title: 'The Mental Elf: More mental health support needed for students in higher education',
      img: dwarf,
      tags: [{ title: 'Mental Health', value: MENTAL_HEALTH }],
      link: '/mental-elf'
    },
    {
      title: `Mental health and higher education: 'I won't let depression hold back my academic career'`,
      img: ed,
      tags: [
        { title: 'Mental Health/', value: MENTAL_HEALTH },
        { title: 'Education', value: EDUCATION }
      ],
      link: '/mental-health-and-higher-education'
    },
    {
      title: `Studying with Social Distancing.`,
      img: pic_40,
      tags: [{ title: 'Mental Health', value: MENTAL_HEALTH }],
      link: '/studying-with-social-distancing'
    },
    {
      title: `Eleanor Longden: The voices in my head`,
      img: pic_13,
      tags: [{ title: 'Mental Health', value: MENTAL_HEALTH }],
      link: '/eleanor-longden-the-voices-in-my-head'
    },
    {
      title: `The Norah Fry Research Centre`,
      img: pic_14,
      tags: [{ title: 'Mental Health', value: MENTAL_HEALTH }],
      link: '/the-norah-fry-research-centre'
    },
    {
      title: `Mind, the mental health charity.`,
      img: pic_15,
      tags: [{ title: 'Mental Health', value: MENTAL_HEALTH }],
      href: 'https://www.mind.org.uk/'
    },
    {
      title: `SANE Charity`,
      img: pic_16,
      tags: [{ title: 'Mental Health', value: MENTAL_HEALTH }],
      href: 'https://www.sane.org.uk/'
    },
    {
      title: `Don't worry, be happy: overcoming worry may be key to mental health`,
      img: pic_17,
      tags: [{ title: 'Mental Health', value: MENTAL_HEALTH }],
      link: '/dont-worry-be-happy'
    }
  ],
  AUTISM: [
    {
      title: `Asperger's Confounds Colleges`,
      img: asperger,
      tags: [{ title: 'AUTISM', value: AUTISM }],
      link: '/aspergers-confounds-colleges'
    },
    {
      title: `When will we understand Autism Spectrum Disorders?`,
      img: pic_2,
      tags: [{ title: 'AUTISM', value: AUTISM }],
      link: '/when-will-we-understand-autism-spectrum-disorders'
    },
    {
      title: `Autism: how computers can help`,
      img: pic_3,
      tags: [{ title: 'AUTISM', value: AUTISM }],
      link: '/autism-how-computer-can-help'
    },
    {
      title: `Asperger's: 'This is our normal'`,
      img: pic_4,
      tags: [{ title: 'AUTISM', value: AUTISM }],
      link: '/asperger-is-this-is-our-normal'
    }
  ],
  DEAF_AWARE: [
    {
      title: `Assistive Solutions BSL Tutors`,
      img: bsl_tutors,
      tags: [{ title: 'Deaf Aware', value: DEAF_AWARE }],
      link: '/assistive-solutions-bsl-tutors'
    },
    {
      title: `Snowdon Award Scheme`,
      img: pic_5,
      tags: [{ title: 'Deaf Aware', value: DEAF_AWARE }],
      link: '/snowdon-award-scheme'
    },
    {
      title: `Action on Hearing Loss`,
      img: pic_6,
      tags: [{ title: 'Deaf Aware', value: DEAF_AWARE }],
      link: 'coming-soon'
    },
    {
      title: `Why the Deaf Have Enhanced Vision`,
      img: pic_7,
      tags: [{ title: 'Deaf Aware', value: DEAF_AWARE }],
      link: '/why-the-deaf-have-enhanced-vision'
    }
  ],
  SPLD_DYSLEXIA: [
    {
      title: `Essay Writing`,
      img: essay,
      tags: [{ title: 'SPLD / Dyslexia', value: SPLD_DYSLEXIA }],
      link: '/essay-writing'
    },
    {
      title: `Joshua Foer: Feats of memory anyone can do`,
      img: pic_8,
      tags: [{ title: 'SPLD / Dyslexia', value: SPLD_DYSLEXIA }],
      link: '/joshua-foer-feats-of-memory-anyone-can-do'
    },
    {
      title: `In praise of misfits`,
      img: pic_9,
      tags: [{ title: 'SPLD / Dyslexia', value: SPLD_DYSLEXIA }],
      link: '/in-praise-of-misfits'
    },
    {
      title: `Diagnosing Dyslexia. A Guide to the Assessment of Adults with Specific Learning Difficulties`,
      img: pic_10,
      tags: [{ title: 'SPLD / Dyslexia', value: SPLD_DYSLEXIA }],
      link: '/diagnosing-dyslexia-guide-to-the-assessment-of-adults-with-specific-learning-difficulties'
    },
    {
      title: `Memrise`,
      img: pic_11,
      tags: [{ title: 'SPLD / Dyslexia', value: SPLD_DYSLEXIA }],
      link: '/memrise'
    },
    {
      title: `Secret of the super successful ...they’re dyslexic`,
      img: pic_12,
      tags: [{ title: 'SPLD / Dyslexia', value: SPLD_DYSLEXIA }],
      link: '/secret-of-the-super-successful-they-are-dyslexic'
    }
  ],
  EDUCATION: [
    {
      title: `Mental health and higher education: 'I won't let depression hold back my academic career'`,
      img: ed,
      tags: [
        { title: 'Mental Health / ', value: MENTAL_HEALTH },
        { id: 1, title: 'Education', value: EDUCATION }
      ],
      link: '/mental-health-and-higher-education'
    },
    {
      title: `RSA Animate: Changing Education Paradigms`,
      img: rsa,
      tags: [{ title: 'Education', value: EDUCATION }],
      link: '/rsa-animate-changing-education-paradigms'
    },
    {
      title: `Turning Learning Right Side Up Book Talk`,
      img: turning,
      tags: [{ title: 'Education', value: EDUCATION }],
      link: '/turning-learning-right-side-up-book-talk'
    },
    {
      title: `Salman Khan talk at TED 2011`,
      img: turning2,
      tags: [{ title: 'Education', value: EDUCATION }],
      link: '/salman-khan-talk-at-ted-2011'
    },
    {
      title: `Disabled students ‘still need more help’, says report`,
      img: turning3,
      tags: [{ title: 'Education', value: EDUCATION }],
      link: '/disabled-students-still-need-more-help'
    },
    {
      title: `Five secrets to revising that can boost your grades`,
      img: turning4,
      tags: [{ title: 'Education', value: EDUCATION }],
      link: '/five-secrets-to-revising-that-can-boost-your-grades'
    },
    {
      title: `My Life as a Disabled Student`,
      img: turning5,
      tags: [{ title: 'Education', value: EDUCATION }],
      link: '/my-life-as-a-disabled-student'
    },
    {
      title: `Students: 10 ways to beat stress`,
      img: turning6,
      tags: [{ title: 'Education', value: EDUCATION }],
      link: '/students-10-ways-to-beat-stress'
    },
    {
      title: `How to escape education's death valley | Sir Ken Robinson`,
      img: pic_1,
      tags: [
        { title: 'All Content/', value: ALL },
        { title: 'AT Training/', value: AT_TRAINING },
        { title: 'Education', value: EDUCATION }
      ],
      link: 'coming-soon'
    }
  ],
  DSA: [
    {
      title: `DSA. What, when and how to apply`,
      img: dsa,
      tags: [{ title: 'DSA', value: DSA }],
      link: 'coming-soon'
    },
    {
      title: `Disabled Students’ Allowances (DSAs) in higher education 2013/14`,
      img: dsa2,
      tags: [{ title: 'DSA', value: DSA }],
      link: '/disabled-students-allowances'
    }
  ],
  EMPLOYMENT: [
    {
      title: `Students: how to use LinkedIn at uni to help you get a job when you graduate`,
      img: empl,
      tags: [{ title: 'Employment', value: EMPLOYMENT }],
      link: '/students-how-to-use-linkedin-at-uni-to-help-you-get-a-job-when-you-graduate'
    },
    {
      title: `The ten skills students really need when they graduate`,
      img: empl1,
      tags: [{ title: 'Employment', value: EMPLOYMENT }],
      link: '/the-ten-skills-students-really-need-when-they-graduate'
    },
    {
      title: `Will my disability affect my graduate job application?`,
      img: empl2,
      tags: [{ title: 'Employment', value: EMPLOYMENT }],
      link: '/will-my-disability-affect-my-graduate-job-application'
    },
    {
      title: `Employability`,
      img: pic_24,
      tags: [{ title: 'Employment', value: EMPLOYMENT }],
      link: '/employability'
    },
    {
      title: `AchieveAbility`,
      img: pic_25,
      tags: [{ title: 'Employment', value: EMPLOYMENT }],
      href: 'https://www.achieveability.org.uk/'
    }
  ],
  PROCRASTINATION: [
    {
      title: `Why Procrastinators Procrastinate`,
      img: pic_18,
      tags: [{ title: 'Procrastination', value: PROCRASTINATION }],
      link: '/why-procrastinators-procrastinate'
    },
    {
      title: `How to Beat Procrastination`,
      img: pic_19,
      tags: [{ title: 'Procrastination', value: PROCRASTINATION }],
      link: '/how-to-beat-procrastination'
    },
    {
      title: `24 Invaluable Skills To Learn For Free Online This Year`,
      img: pic_39,
      tags: [{ title: 'Procrastination', value: PROCRASTINATION }],
      link: '/invaluable-skills-to-learn-for-free-online-this-year'
    },
    {
      title: `How To Get A Whole Lot Of Sh*t Done In A Day`,
      img: pic_20,
      tags: [{ title: 'Procrastination', value: PROCRASTINATION }],
      link: '/how-to-get-a-whole-lot-of-sh*t-done-in-a-day'
    },
    {
      title: `Meditation For People Who Don’t Meditate`,
      img: pic_21,
      tags: [{ title: 'Procrastination', value: PROCRASTINATION }],
      link: '/meditation-for-people-who-dont-meditate'
    }
  ],
  EQUALITY_ACT: [
    {
      title: `Equality Act 2010`,
      img: pic_22,
      tags: [{ title: 'Equality Act', value: EQUALITY_ACT }],
      link: '/equality-act'
    },
    {
      title: `Martyn Sibley Blog`,
      img: pic_23,
      tags: [{ title: 'Equality Act', value: EQUALITY_ACT }],
      link: '/martyn-sibley-blog'
    }
  ],
  FREE_SOFTWARE: [
    {
      title: `Dropbox`,
      img: pic_26,
      tags: [{ title: 'Free software', value: FREE_SOFTWARE }],
      href: 'https://www.dropbox.com/'
    },
    {
      title: `Zotero`,
      img: pic_27,
      tags: [{ title: 'Free software', value: FREE_SOFTWARE }],
      href: 'https://www.zotero.org/'
    },
    {
      title: `World Web Dictionary`,
      img: pic_28,
      tags: [{ title: 'Free software', value: FREE_SOFTWARE }],
      href: 'http://www.wordweb.info/'
    },
    {
      title: `Evernote`,
      img: pic_29,
      tags: [{ title: 'Free software', value: FREE_SOFTWARE }],
      href: 'https://evernote.com/intl/ru'
    },
    {
      title: `XMind`,
      img: pic_30,
      tags: [{ title: 'Free software', value: FREE_SOFTWARE }],
      href: 'https://xmind.app/'
    },
    {
      title: `F.lux`,
      img: pic_31,
      tags: [{ title: 'Free software', value: FREE_SOFTWARE }],
      href: 'http://justgetflux.com/'
    },
    {
      title: `Nocturne`,
      img: pic_32,
      tags: [{ title: 'Free software', value: FREE_SOFTWARE }],
      href: 'https://www.macupdate.com/discontinued-apps'
    },
    {
      title: `Virtual Magnifying Glass`,
      img: pic_33,
      tags: [{ title: 'Free software', value: FREE_SOFTWARE }],
      href: 'https://www.macupdate.com/app/mac/41079/virtual-magnifying-glass'
    },
    {
      title: `Self Control`,
      img: pic_34,
      tags: [{ title: 'Free software', value: FREE_SOFTWARE }],
      href: 'http://selfcontrolapp.com/'
    },
    {
      title: `Q10`,
      img: pic_35,
      tags: [{ title: 'Free software', value: FREE_SOFTWARE }],
      href: 'http://www.baara.com/q10/'
    },
    {
      title: `Focus Writer`,
      img: pic_36,
      tags: [{ title: 'Free software', value: FREE_SOFTWARE }],
      href: 'https://gottcode.org/focuswriter/'
    },
    {
      title: `Firefox Reader`,
      img: pic_37,
      tags: [{ title: 'Free software', value: FREE_SOFTWARE }],
      href: 'https://addons.mozilla.org/en-US/firefox/addon/reader/'
    },
    {
      title: `Scrible`,
      img: pic_38,
      tags: [{ title: 'Free software', value: FREE_SOFTWARE }],
      href: 'http://www.scrible.com/'
    }
  ],
  AT_TRAINING: [
    {
      title: `How to escape education's death valley | Sir Ken Robinson`,
      img: pic_1,
      tags: [
        { title: 'All Content/', value: ALL },
        { title: 'AT Training/', value: AT_TRAINING },
        { title: 'Education', value: EDUCATION }
      ],
      link: 'coming-soon'
    }
  ],
  // LEARNING_RESOURCES: [],
  // ASCS: [],
  // RECYCLING: [],
}

export const otherResources = {
  ALL: [
    {
      title: `How to escape education's death valley | Sir Ken Robinson`,
      img: pic_1,
      tags: [
        { title: 'All Content/', value: ALL },
        { title: 'AT Training/', value: AT_TRAINING },
        { title: 'Education', value: EDUCATION }
      ],
      link: 'coming-soon'
    },
    {
      title: `When will we understand Autism Spectrum Disorders?`,
      img: pic_2,
      tags: [{ title: 'AUTISM', value: AUTISM }],
      link: '/when-will-we-understand-autism-spectrum-disorders'
    },
    {
      title: `Autism: how computers can help`,
      img: pic_3,
      tags: [{ title: 'AUTISM', value: AUTISM }],
      link: '/autism-how-computer-can-help'
    },
    {
      title: `Asperger's: 'This is our normal'`,
      img: pic_4,
      tags: [{ title: 'AUTISM', value: AUTISM }],
      link: '/asperger-is-this-is-our-normal'
    },
    {
      title: `Snowdon Award Scheme`,
      img: pic_5,
      tags: [{ title: 'Deaf Aware', value: DEAF_AWARE }],
      link: '/snowdon-award-scheme'
    },
    {
      title: `Action on Hearing Loss`,
      img: pic_6,
      tags: [{ title: 'Deaf Aware', value: DEAF_AWARE }],
      link: 'coming-soon'
    },
    {
      title: `Why the Deaf Have Enhanced Vision`,
      img: pic_7,
      tags: [{ title: 'Deaf Aware', value: DEAF_AWARE }],
      link: '/why-the-deaf-have-enhanced-vision'
    },
    {
      title: `Joshua Foer: Feats of memory anyone can do`,
      img: pic_8,
      tags: [{ title: 'SPLD / Dyslexia', value: SPLD_DYSLEXIA }],
      link: '/joshua-foer-feats-of-memory-anyone-can-do'
    },
    {
      title: `In praise of misfits`,
      img: pic_9,
      tags: [{ title: 'SPLD / Dyslexia', value: SPLD_DYSLEXIA }],
      link: '/in-praise-of-misfits'
    },
    {
      title: `Diagnosing Dyslexia. A Guide to the Assessment of Adults with Specific Learning Difficulties`,
      img: pic_10,
      tags: [{ title: 'SPLD / Dyslexia', value: SPLD_DYSLEXIA }],
      link: '/diagnosing-dyslexia-guide-to-the-assessment-of-adults-with-specific-learning-difficulties'
    },
    {
      title: `Memrise`,
      img: pic_11,
      tags: [{ title: 'SPLD / Dyslexia', value: SPLD_DYSLEXIA }],
      link: '/memrise'
    },
    {
      title: `Secret of the super successful ...they’re dyslexic`,
      img: pic_12,
      tags: [{ title: 'SPLD / Dyslexia', value: SPLD_DYSLEXIA }],
      link: '/secret-of-the-super-successful-they-are-dyslexic'
    },
    {
      title: `Eleanor Longden: The voices in my head`,
      img: pic_13,
      tags: [{ title: 'Mental Health', value: MENTAL_HEALTH }],
      link: '/eleanor-longden-the-voices-in-my-head'
    },
    {
      title: `The Norah Fry Research Centre`,
      img: pic_14,
      tags: [{ title: 'Mental Health', value: MENTAL_HEALTH }],
      link: '/the-norah-fry-research-centre'
    },
    {
      title: `Mind, the mental health charity.`,
      img: pic_15,
      tags: [{ title: 'Mental Health', value: MENTAL_HEALTH }],
      href: 'https://www.mind.org.uk/'
    },
    {
      title: `SANE Charity`,
      img: pic_16,
      tags: [{ title: 'Mental Health', value: MENTAL_HEALTH }],
      href: 'https://www.sane.org.uk/'
    },
    {
      title: `Don't worry, be happy: overcoming worry may be key to mental health`,
      img: pic_17,
      tags: [{ title: 'Mental Health', value: MENTAL_HEALTH }],
      link: '/dont-worry-be-happy'
    },
    {
      title: `Why Procrastinators Procrastinate`,
      img: pic_18,
      tags: [{ title: 'Procrastination', value: PROCRASTINATION }],
      link: '/why-procrastinators-procrastinate'
    },
    {
      title: `How to Beat Procrastination`,
      img: pic_19,
      tags: [{ title: 'Procrastination', value: PROCRASTINATION }],
      link: '/how-to-beat-procrastination'
    },
    {
      title: `24 Invaluable Skills To Learn For Free Online This Year`,
      img: pic_39,
      tags: [{ title: 'Procrastination', value: PROCRASTINATION }],
      link: '/invaluable-skills-to-learn-for-free-online-this-year'
    },
    {
      title: `How To Get A Whole Lot Of Sh*t Done In A Day`,
      img: pic_20,
      tags: [{ title: 'Procrastination', value: PROCRASTINATION }],
      link: '/how-to-get-a-whole-lot-of-sh*t-done-in-a-day'
    },
    {
      title: `Meditation For People Who Don’t Meditate`,
      img: pic_21,
      tags: [{ title: 'Procrastination', value: PROCRASTINATION }],
      link: '/meditation-for-people-who-dont-meditate'
    },
    {
      title: `Equality Act 2010`,
      img: pic_22,
      tags: [{ title: 'Equality Act', value: EQUALITY_ACT }],
      link: '/equality-act'
    },
    {
      title: `Martyn Sibley Blog`,
      img: pic_23,
      tags: [{ title: 'Equality Act', value: EQUALITY_ACT }],
      link: '/martyn-sibley-blog'
    },
    {
      title: `Employability`,
      img: pic_24,
      tags: [{ title: 'Employment', value: EMPLOYMENT }],
      link: '/employability'
    },
    {
      title: `AchieveAbility`,
      img: pic_25,
      tags: [{ title: 'Employment', value: EMPLOYMENT }],
      href: 'https://www.achieveability.org.uk/'
    },
    {
      title: `Dropbox`,
      img: pic_26,
      tags: [{ title: 'Free software', value: FREE_SOFTWARE }],
      href: 'https://www.dropbox.com/'
    },
    {
      title: `Zotero`,
      img: pic_27,
      tags: [{ title: 'Free software', value: FREE_SOFTWARE }],
      href: 'https://www.zotero.org/'
    },
    {
      title: `World Web Dictionary`,
      img: pic_28,
      tags: [{ title: 'Free software', value: FREE_SOFTWARE }],
      href: 'http://www.wordweb.info/'
    },
    {
      title: `Evernote`,
      img: pic_29,
      tags: [{ title: 'Free software', value: FREE_SOFTWARE }],
      href: 'https://evernote.com/intl/ru'
    },
    {
      title: `XMind`,
      img: pic_30,
      tags: [{ title: 'Free software', value: FREE_SOFTWARE }],
      href: 'https://xmind.app/'
    },
    {
      title: `F.lux`,
      img: pic_31,
      tags: [{ title: 'Free software', value: FREE_SOFTWARE }],
      href: 'http://justgetflux.com/'
    },
    {
      title: `Nocturne`,
      img: pic_32,
      tags: [{ title: 'Free software', value: FREE_SOFTWARE }],
      href: 'https://www.macupdate.com/discontinued-apps'
    },
    {
      title: `Virtual Magnifying Glass`,
      img: pic_33,
      tags: [{ title: 'Free software', value: FREE_SOFTWARE }],
      href: 'https://www.macupdate.com/app/mac/41079/virtual-magnifying-glass'
    },
    {
      title: `Self Control`,
      img: pic_34,
      tags: [{ title: 'Free software', value: FREE_SOFTWARE }],
      href: 'http://selfcontrolapp.com/'
    },
    {
      title: `Q10`,
      img: pic_35,
      tags: [{ title: 'Free software', value: FREE_SOFTWARE }],
      href: 'http://www.baara.com/q10/'
    },
    {
      title: `Focus Writer`,
      img: pic_36,
      tags: [{ title: 'Free software', value: FREE_SOFTWARE }],
      href: 'https://gottcode.org/focuswriter/'
    },
    {
      title: `Firefox Reader`,
      img: pic_37,
      tags: [{ title: 'Free software', value: FREE_SOFTWARE }],
      href: 'https://addons.mozilla.org/en-US/firefox/addon/reader/'
    },
    {
      title: `Scrible`,
      img: pic_38,
      tags: [{ title: 'Free software', value: FREE_SOFTWARE }],
      href: 'http://www.scrible.com/'
    }
  ],
  MENTAL_HEALTH: [
    {
      title: `Eleanor Longden: The voices in my head`,
      img: pic_13,
      tags: [{ title: 'Mental Health', value: MENTAL_HEALTH }],
      link: '/eleanor-longden-the-voices-in-my-head'
    },
    {
      title: `The Norah Fry Research Centre`,
      img: pic_14,
      tags: [{ title: 'Mental Health', value: MENTAL_HEALTH }],
      link: '/the-norah-fry-research-centre'
    },
    {
      title: `Mind, the mental health charity.`,
      img: pic_15,
      tags: [{ title: 'Mental Health', value: MENTAL_HEALTH }],
      href: 'https://www.mind.org.uk/'
    },
    {
      title: `SANE Charity`,
      img: pic_16,
      tags: [{ title: 'Mental Health', value: MENTAL_HEALTH }],
      href: 'https://www.sane.org.uk/'
    },
    {
      title: `Don't worry, be happy: overcoming worry may be key to mental health`,
      img: pic_17,
      tags: [{ title: 'Mental Health', value: MENTAL_HEALTH }],
      link: '/dont-worry-be-happy'
    }
  ],
  //r
  AUTISM: [
    {
      title: `When will we understand Autism Spectrum Disorders?`,
      img: pic_2,
      tags: [{ title: 'AUTISM', value: AUTISM }],
      link: '/when-will-we-understand-autism-spectrum-disorders'
    },
    {
      title: `Autism: how computers can help`,
      img: pic_3,
      tags: [{ title: 'AUTISM', value: AUTISM }],
      link: '/autism-how-computer-can-help'
    },
    {
      title: `Asperger's: 'This is our normal'`,
      img: pic_4,
      tags: [{ title: 'AUTISM', value: AUTISM }],
      link: '/asperger-is-this-is-our-normal'
    }
  ],
  //r
  DEAF_AWARE: [
    {
      title: `Snowdon Award Scheme`,
      img: pic_5,
      tags: [{ title: 'Deaf Aware', value: DEAF_AWARE }],
      link: '/snowdon-award-scheme'
    },
    {
      title: `Action on Hearing Loss`,
      img: pic_6,
      tags: [{ title: 'Deaf Aware', value: DEAF_AWARE }],
      link: 'coming-soon'
    },
    {
      title: `Why the Deaf Have Enhanced Vision`,
      img: pic_7,
      tags: [{ title: 'Deaf Aware', value: DEAF_AWARE }],
      link: '/why-the-deaf-have-enhanced-vision'
    }
  ],
  //r
  SPLD_DYSLEXIA: [
    {
      title: `Joshua Foer: Feats of memory anyone can do`,
      img: pic_8,
      tags: [{ title: 'SPLD / Dyslexia', value: SPLD_DYSLEXIA }],
      link: '/joshua-foer-feats-of-memory-anyone-can-do'
    },
    {
      title: `In praise of misfits`,
      img: pic_9,
      tags: [{ title: 'SPLD / Dyslexia', value: SPLD_DYSLEXIA }],
      link: '/in-praise-of-misfits'
    },
    {
      title: `Diagnosing Dyslexia. A Guide to the Assessment of Adults with Specific Learning Difficulties`,
      img: pic_10,
      tags: [{ title: 'SPLD / Dyslexia', value: SPLD_DYSLEXIA }],
      link: '/diagnosing-dyslexia-guide-to-the-assessment-of-adults-with-specific-learning-difficulties'
    },
    {
      title: `Memrise`,
      img: pic_11,
      tags: [{ title: 'SPLD / Dyslexia', value: SPLD_DYSLEXIA }],
      link: '/memrise'
    },
    {
      title: `Secret of the super successful ...they’re dyslexic`,
      img: pic_12,
      tags: [{ title: 'SPLD / Dyslexia', value: SPLD_DYSLEXIA }],
      link: '/secret-of-the-super-successful-they-are-dyslexic'
    }
  ],
  //
  EDUCATION: [
    {
      title: `How to escape education's death valley | Sir Ken Robinson`,
      img: pic_1,
      tags: [
        { title: 'All Content/', value: ALL },
        { title: 'AT Training/', value: AT_TRAINING },
        { title: 'Education', value: EDUCATION }
      ],
      link: 'coming-soon'
    }
  ],
  //
  EMPLOYMENT: [
    {
      title: `Employability`,
      img: pic_24,
      tags: [{ title: 'Employment', value: EMPLOYMENT }],
      link: '/employability'
    },
    {
      title: `AchieveAbility`,
      img: pic_25,
      tags: [{ title: 'Employment', value: EMPLOYMENT }],
      href: 'https://www.achieveability.org.uk/'
    }
  ],
  //
  PROCRASTINATION: [
    {
      title: `Why Procrastinators Procrastinate`,
      img: pic_18,
      tags: [{ title: 'Procrastination', value: PROCRASTINATION }],
      link: '/why-procrastinators-procrastinate'
    },
    {
      title: `How to Beat Procrastination`,
      img: pic_19,
      tags: [{ title: 'Procrastination', value: PROCRASTINATION }],
      link: '/how-to-beat-procrastination'
    },
    {
      title: `24 Invaluable Skills To Learn For Free Online This Year`,
      img: pic_39,
      tags: [{ title: 'Procrastination', value: PROCRASTINATION }],
      link: '/invaluable-skills-to-learn-for-free-online-this-year'
    },
    {
      title: `How To Get A Whole Lot Of Sh*t Done In A Day`,
      img: pic_20,
      tags: [{ title: 'Procrastination', value: PROCRASTINATION }],
      link: '/how-to-get-a-whole-lot-of-sh*t-done-in-a-day'
    },
    {
      title: `Meditation For People Who Don’t Meditate`,
      img: pic_21,
      tags: [{ title: 'Procrastination', value: PROCRASTINATION }],
      link: '/meditation-for-people-who-dont-meditate'
    }
  ],
  // 
  EQUALITY_ACT: [
    {
      title: `Equality Act 2010`,
      img: pic_22,
      tags: [{ title: 'Equality Act', value: EQUALITY_ACT }],
      link: '/equality-act'
    },
    {
      title: `Martyn Sibley Blog`,
      img: pic_23,
      tags: [{ title: 'Equality Act', value: EQUALITY_ACT }],
      link: '/martyn-sibley-blog'
    }
  ],
  FREE_SOFTWARE: [
    {
      title: `Dropbox`,
      img: pic_26,
      tags: [{ title: 'Free software', value: FREE_SOFTWARE }],
      href: 'https://www.dropbox.com/'
    },
    {
      title: `Zotero`,
      img: pic_27,
      tags: [{ title: 'Free software', value: FREE_SOFTWARE }],
      href: 'https://www.zotero.org/'
    },
    {
      title: `World Web Dictionary`,
      img: pic_28,
      tags: [{ title: 'Free software', value: FREE_SOFTWARE }],
      href: 'http://www.wordweb.info/'
    },
    {
      title: `Evernote`,
      img: pic_29,
      tags: [{ title: 'Free software', value: FREE_SOFTWARE }],
      href: 'https://evernote.com/intl/ru'
    },
    {
      title: `XMind`,
      img: pic_30,
      tags: [{ title: 'Free software', value: FREE_SOFTWARE }],
      href: 'https://xmind.app/'
    },
    {
      title: `F.lux`,
      img: pic_31,
      tags: [{ title: 'Free software', value: FREE_SOFTWARE }],
      href: 'http://justgetflux.com/'
    },
    {
      title: `Nocturne`,
      img: pic_32,
      tags: [{ title: 'Free software', value: FREE_SOFTWARE }],
      href: 'https://www.macupdate.com/discontinued-apps'
    },
    {
      title: `Virtual Magnifying Glass`,
      img: pic_33,
      tags: [{ title: 'Free software', value: FREE_SOFTWARE }],
      href: 'https://www.macupdate.com/app/mac/41079/virtual-magnifying-glass'
    },
    {
      title: `Self Control`,
      img: pic_34,
      tags: [{ title: 'Free software', value: FREE_SOFTWARE }],
      href: 'http://selfcontrolapp.com/'
    },
    {
      title: `Q10`,
      img: pic_35,
      tags: [{ title: 'Free software', value: FREE_SOFTWARE }],
      href: 'http://www.baara.com/q10/'
    },
    {
      title: `Focus Writer`,
      img: pic_36,
      tags: [{ title: 'Free software', value: FREE_SOFTWARE }],
      href: 'https://gottcode.org/focuswriter/'
    },
    {
      title: `Firefox Reader`,
      img: pic_37,
      tags: [{ title: 'Free software', value: FREE_SOFTWARE }],
      href: 'https://addons.mozilla.org/en-US/firefox/addon/reader/'
    },
    {
      title: `Scrible`,
      img: pic_38,
      tags: [{ title: 'Free software', value: FREE_SOFTWARE }],
      href: 'http://www.scrible.com/'
    }
  ],
  AT_TRAINING: [
    {
      title: `How to escape education's death valley | Sir Ken Robinson`,
      img: pic_1,
      tags: [
        { title: 'All Content/', value: ALL },
        { title: 'AT Training/', value: AT_TRAINING },
        { title: 'Education', value: EDUCATION }
      ],
      link: 'coming-soon'
    }
  ],
  DSA: [],
  LEARNING_RESOURCES: [],
  ASCS: [],
  RECYCLING: []
}

export const studentsTags = [
  { title: 'All content', value: studentsResources.ALL },
  { title: 'SPLD / Dyslexia', value: studentsResources.SPLD_DYSLEXIA },
  { title: 'Mental Health', value: studentsResources.MENTAL_HEALTH },
  { title: 'DSA ', value: studentsResources.DSA },
  { title: 'Education', value: studentsResources.EDUCATION },
  { title: 'Employment ', value: studentsResources.EMPLOYMENT },
  { title: 'Deaf Aware', value: studentsResources.DEAF_AWARE },
  { title: 'Procrastination', value: studentsResources.PROCRASTINATION },
  { title: 'Equality Act', value: studentsResources.EQUALITY_ACT },
  // { title: 'Learning Resources', value: otherResources.LEARNING_RESOURCES },
  // { title: 'ASCs', value: studentsResources.ASCS },
  // { title: 'Recycling', value: studentsResources.RECYCLING },
  { title: 'Free software', value: studentsResources.FREE_SOFTWARE }
]
export const otherTags = [
  // { title: 'All content', value: otherResources.ALL },
  // { title: 'SPLD / Dyslexia', value: otherResources.SPLD_DYSLEXIA },
  // { title: 'Mental Health', value: otherResources.MENTAL_HEALTH },
  // { title: 'Procrastination', value: otherResources.PROCRASTINATION },
  // { title: 'Equality Acts', value: otherResources.EQUALITY_ACT },
  // { title: 'Education', value: otherResources.EDUCATION },
  // { title: 'Employment ', value: otherResources.EMPLOYMENT },
  // { title: 'Free software', value: otherResources.FREE_SOFTWARE },
  // { title: 'Deaf Aware', value: otherResources.DEAF_AWARE }
  // { title: 'DSA ', value: otherResources.DSA },
  // { title: 'Learning Resources', value: otherResources.LEARNING_RESOURCES },
  // { title: 'ASCs', value: otherResources.ASCS },
  // { title: 'Recycling', value: otherResources.RECYCLING },
]
